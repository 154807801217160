<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <section id="homepage_v1">
    <b-row>
      <b-col cols="6">
        <b-card
          class="custom-todo"
          no-body
        >
          <b-card-header>
            <b-card-title> {{ $t('To-do List') }} </b-card-title>
          </b-card-header>
          <b-card-body class="pb-0">
            <div>
              <li
                v-for="(item0, index) in todoList"
                :key="index"
                class="custom-todo__items"
              >
                <router-link :to="item0.url">
                  <span> {{ item0.value }} </span>
                  {{ $t(item0.text) }}
                </router-link>
              </li>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card
          class="custom-setup"
          no-body
        >
          <b-card-header>
            <b-card-title> {{ $t('Let’s set up your site') }} </b-card-title>
          </b-card-header>
          <b-card-body class="pb-0">
            <div
              v-for="(itemx, index) in setupList"
              :key="index"
              class="d-flex align-items-center custom-setup__items"
            >
              <feather-icon
                v-if="item.status"
                icon="CheckCircleIcon"
                size="24"
                class="text-success cursor-pointer mr-1"
              />
              <feather-icon
                v-else
                icon="CircleIcon"
                size="24"
                class="text-warning cursor-pointer mr-1"
              />
              <router-link :to="itemx.url">
                <h4>{{ $t(itemx.text) }}</h4>
                <div>{{ $t(itemx.text_sub) }}</div>
              </router-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-body>
            <div class="d-flex items-center align-items-center">
              <span class="mr-1">{{ $t('Time frame') }}</span>
              <v-select
                v-if="dayOptions"
                v-model="perDay"
                label="name"
                :reduce="(x) => x.id"
                :options="dayOptions"
                class="m-w-215"
                @input="loadAll"
              />
              <span class="ml-2">{{ currentDate }}</span>
            </div>
          </b-card-body>

        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title> {{ $t('Key metrics') }} </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="d-flex">
              <div
                v-for="(item2, index) in keymetricList"
                :key="index"
                class="keys"
              >
                <div class="text">
                  {{ $t(item2.text) }}
                  <feather-icon
                    v-b-tooltip.hover
                    icon="HelpCircleIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                    :title="$t(item2.description)"
                    variant="gradient-primary"
                  />
                </div>
                <div
                  v-if="item2.is_price"
                  class="price"
                >
                  ${{ item2.value }}
                </div>
                <div
                  v-else-if="item2.is_rate"
                  class="price"
                >
                  {{ item2.value }}%
                </div>
                <div
                  v-else
                  class="price"
                >
                  {{ item2.value }}
                </div>
                <div class="sub">
                  {{ textDay }} {{ item2.percent }}%
                  <feather-icon
                    v-if="item2.percent >= 0"
                    icon="ChevronUpIcon"
                    size="24"
                    class="text-success"
                  />
                  <feather-icon
                    v-else
                    icon="ChevronDownIcon"
                    size="24"
                    class="text-danger"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-tabs content-class="pt-1">
            <b-tab :title="$t('Visitor')">
              <!-- echart -->
              <!-- <app-echart-line
                v-if="visitorChart.isLoaded"
                :option-data="visitorChart.chart"
              /> -->
              <apex-line-area-chart
                v-if="visitorChart.isLoaded"
                :option-data="visitorChart.chart"
              />
            </b-tab>
            <b-tab :title="$t('Order')">
              <!-- echart -->
              <!-- <app-echart-line
                v-if="orderChart.isLoaded"
                :option-data="orderChart.chart"
              /> -->
              <apex-line-area-chart
                v-if="orderChart.isLoaded"
                :option-data="orderChart.chart"
              />
            </b-tab>
            <b-tab :title="$t('Sales')">
              <!-- echart -->
              <!-- <app-echart-line
                v-if="saleChart.isLoaded"
                :option-data="saleChart.chart"
              /> -->
              <apex-line-area-chart
                v-if="saleChart.isLoaded"
                :option-data="saleChart.chart"
              />
            </b-tab>
          </b-tabs>
          <!-- <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex mb-1 mb-sm-0">
              <h3 class="text-active active">
                Visitor by days
              </h3>
              <h3 class="text-active">
                Order by days
              </h3>
              <h3 class="text-active">
                Sales by days
              </h3>
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
              <h1 class="font-weight-bolder">
                183,382
              </h1>
            </div>
          </div>

          <app-echart-line
            v-if="visitorChart.isLoaded"
            :option-data="visitorChart.chart"
          /> -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  VBTooltip,
  BTabs,
  BTab,
  // BCardText,
  // BBadge,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
// import HomepageAnalyticRevenue from '../homepage/HomepageAnalyticRevenue.vue'
// import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ApexLineAreaChart from './components/ApexLineAreaChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    // BCardText,
    // HomepageAnalyticRevenue,
    // BBadge,
    BTabs,
    BTab,
    // AppEchartLine,
    ApexLineAreaChart,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      siteStatus: {
        config: 0,
        product: 0,
        pay: 0,
      },
      perDay: 7,
      textDay: 'in the last 7 days',
      // currentDate: `${this.getCurrentDate(
      //   false,
      //   '-',
      //   7,
      // )} to ${this.getCurrentDate(false, '-')}`,
      dayOptions: [
        {
          id: 7,
          name: 'in the last 7 days',
        },
        {
          id: 14,
          name: 'in the last 14 days',
        },
        {
          id: 30,
          name: 'in the last 30 days',
        },
      ],
      item: 'hello',
      items: [
        { value: 'hello', en: 'hello world', es: 'hola mundo' },
        { value: 'bye', en: 'bye world', es: 'adios mundo' },
      ],
      visitorChart: {
        // xAxisData: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
        // series: ['9000', '2000', '2100', '1900', '2500', '2200', '800', '900', '2000', '1500', '1600', '1000', '1400', '1000'],
        xAxisData: [],
        series: [],
      },
      orderChart: {
        xAxisData: [],
        series: [],
      },
      saleChart: {
        xAxisData: [],
        series: [],
      },
      todoList: [
        // {
        //   value: 12,
        //   text: 'orders waiting for confimation',
        //   url: '/order/list',
        // },
        // {
        //   value: 6,
        //   text: 'orders waiting for charge back',
        //   url: '/',
        // },
        // {
        //   value: 5,
        //   text: 'products nearly sold out',
        //   url: '/',
        // },
        // {
        //   value: 9,
        //   text: 'products sold out',
        //   url: '/',
        // },
      ],
      setupList: [
        // {
        //   status: 0,
        //   text: 'Add information for a contact page',
        //   text_sub: 'Add a contact page to help your customer find you easily',
        //   url: '/',
        // },
        // {
        //   status: 1,
        //   text: 'Start your first product',
        //   text_sub: 'Add new product information of your online store',
        //   url: '/product/add',
        // },
        // {
        //   status: 0,
        //   text: 'Add content for your site',
        //   text_sub: 'Write copy for your web pages and blog posts',
        //   url: '/',
        // },
      ],
      keymetricList: [
        {
          id: 1,
          status: 0,
          text: 'Sale',
          value: 9,
          percent: -12,
          description: 'Tổng đơn hàng đã được xác nhận thành công.',
          is_price: 1,
          is_rate: 0,
        },
        {
          id: 2,
          status: 1,
          text: 'Order',
          value: 6,
          percent: 76,
          description: 'Tổng số đơn đặt hàng đã xác nhận trong khoảng thời gian đã chọn, bao gồm cả đơn đặt hàng đã hủy và trả lại / hoàn tiền.',
          is_price: 0,
          is_rate: 0,
        },
        {
          id: 3,
          status: 0,
          text: 'Cancelled Order',
          value: 2,
          percent: -10,
          description: 'Tổng số đơn hàng đã huỷ trong ngày',
          is_price: 0,
          is_rate: 0,
        },
        {
          id: 4,
          status: 0,
          text: 'Visitor',
          value: 1230,
          percent: 67,
          description: 'Tổng số khách truy cập duy nhất đã xem trang trong khoảng thời gian được chọn. Mỗi khách xem một trang sản phẩm nhiều lần được tính là khách truy cập duy nhất.',
          is_price: 0,
          is_rate: 0,
        },
        {
          id: 5,
          status: 0,
          text: 'Conversion rate',
          value: 23,
          percent: -12,
          description: 'Tổng số khách truy cập và có đơn đã xác nhận chia tổng số khách truy cập trong khoảng thời gian đã chọn.',
          is_price: 0,
          is_rate: 1,
        },
      ],
      sale: {},
      dataSale: {
        description: 'Tổng đơn hàng đã được xác nhận thành công.',
        series: [
          {
            name: 'This Month',
            data: null,
          },
          {
            name: 'Last Month',
            data: null,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: null,
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      order: {},
      dataOrder: {
        description: 'Tổng số đơn đặt hàng đã xác nhận trong khoảng thời gian đã chọn, bao gồm cả đơn đặt hàng đã hủy và trả lại / hoàn tiền.',
        series: [
          {
            name: 'This Month',
            data: null,
          },
          {
            name: 'Last Month',
            data: null,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: null,
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      visitor: {},
      dataVisitor: {
        description: 'Tổng số khách truy cập duy nhất đã xem trang trong khoảng thời gian được chọn. Mỗi khách xem một trang sản phẩm nhiều lần được tính là khách truy cập duy nhất.',
        series: [
          {
            name: 'This Month',
            data: null,
          },
          {
            name: 'Last Month',
            data: null,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: null,
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  computed: {
    currentDate() {
      return `${this.getCurrentDate(
        false,
        '-',
        this.perDay,
      )} to ${this.getCurrentDate(false, '-')}`
    },
  },
  mounted() {
    this.loadTodo()
    this.loadSale(this.currentDate)
    this.loadOrder(this.currentDate)
    this.loadCancelOrder(this.currentDate)
    this.loadVisitor(this.currentDate)
    this.loadConversion(this.currentDate)
    // this.loadSalePerOrder(this.currentDate)
  },
  methods: {
    loadAll() {
      this.loadTodo()
      this.loadSale(this.currentDate)
      this.loadOrder(this.currentDate)
      this.loadCancelOrder(this.currentDate)
      this.loadVisitor(this.currentDate)
      this.loadConversion(this.currentDate)
      this.textDay = this.dayOptions.find(val => val.id === this.perDay).name
    },
    async loadTodo() {
      const setting = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/setting/buso_client_page_config?site_id=${
          JSON.parse(localStorage.getItem('siteID')).id
        }`,
      )
      let missConfig = 0
      let hasPay = 0
      if (setting.data.data && setting.data.data.value) {
        const temp = JSON.parse(setting.data.data.value)
        temp.map(val => {
          if (val.key === 'site_name' && (val.value === 'DomoSite' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'email' && (val.value === 'DemoEmail@gmail.com' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'phone' && (val.value === '0123456789' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'address' && (val.value === 'Demo address for your site, setup from dasboard setting.' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'google_analytics' && (val.value === 'UA-XXX-X' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'facebook_chat' && (val.value === 'facebook.com' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'logo' && (val.value === 'https://beta-fileapi.busodevelopers.com/upload/264…dias/desktop/5e99283f5b157383e0e0e735ace8a7fe.gif' || val.value === '')) {
            missConfig += 1
          } else if (val.key === 'favicon' && (val.value === 'https://beta-fileapi.busodevelopers.com/upload/264…dias/desktop/ab3e82018d8502ee1b7130c13ae889cd.gif' || val.value === '')) {
            missConfig += 1
          }

          if (val.key === 'cod') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          if (val.key === 'st_paypal') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          if (val.key === 'st_momo') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          if (val.key === 'st_bill24') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          if (val.key === 'st_coin') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          if (val.key === 'st_bank') {
            const tempJson = JSON.parse(val.value)
            if (tempJson.is_active && tempJson.is_active === true) {
              hasPay += 1
            }
          }
          return true
        })
        if (missConfig === 0) {
          this.siteStatus.config = 1
        } else {
          this.siteStatus.config = 0
        }

        if (hasPay === 0) {
          this.siteStatus.pay = 0
        } else {
          this.siteStatus.pay = 1
        }
      }

      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/statistic/todo?site_id=${
          JSON.parse(localStorage.getItem('siteID')).id
        }`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          const { todo } = res.data.data
          const setUp = res.data.data.set_up
          this.todoList = [{
            value: todo.orders_waitting,
            text: 'orders waiting for confimation',
            url: '/order/list',
          },
          {
            value: todo.orders_chanrge_back,
            text: 'orders waiting for charge back',
            url: '/',
          },
          {
            value: todo.nearly_sold_out,
            text: 'products nearly sold out',
            url: '/',
          },
          {
            value: todo.sold_out,
            text: 'products sold out',
            url: '/',
          }]

          this.setupList = [
            {
              status: this.siteStatus.config,
              text: 'Add information for a contact page',
              text_sub: 'Add a contact page to help your customer find you easily',
              url: '/config',
            },
            {
              status: setUp.content_site,
              text: 'Start your first product',
              text_sub: 'Add new product information of your online store',
              url: '/product/add',
            },
            {
              status: this.siteStatus.pay,
              text: 'Add payment methods',
              text_sub: 'Add payment information to your site',
              url: '/payment_account',
            },
          ]
        }
      }
    },
    async loadSale(dateFromTo) {
      this.saleChart.isLoaded = false
      const saleReport = await this.loadChart('sales', dateFromTo, 1)
      const saleData = this.keymetricList.find(val => val.id === 1)
      saleData.percent = saleReport.percent
      saleData.value = saleReport.total_new
      this.saleChart = {
        chart: {
          xAxisData: saleReport.chart.day,
          series: saleReport.chart.new,
          series1: saleReport.chart.old,
        },
        isLoaded: true,
      }
    },
    async loadOrder(dateFromTo) {
      this.orderChart.isLoaded = false
      const orderReport = await this.loadChart('sales', dateFromTo, 2)
      const orderData = this.keymetricList.find(val => val.id === 2)
      orderData.percent = orderReport.percent
      orderData.value = orderReport.total_new
      this.orderChart = {
        chart: {
          xAxisData: orderReport.chart.day,
          series: orderReport.chart.new,
          series1: orderReport.chart.old,
        },
        isLoaded: true,
      }
    },
    async loadCancelOrder(dateFromTo) {
      const cancelOrderReport = await this.loadChart('sales', dateFromTo, 3)
      const cancelOrderData = this.keymetricList.find(val => val.id === 3)
      cancelOrderData.percent = cancelOrderReport.percent
      cancelOrderData.value = cancelOrderReport.total_new
      this.orderChart = {
        isLoaded: true,
        chart: {
          xAxisData: cancelOrderReport.chart.day,
          series: cancelOrderReport.chart.new,
        },
      }
    },
    async loadVisitor(dateFromTo) {
      this.visitorChart.isLoaded = false
      const visitorReport = await this.loadChart('visitor', dateFromTo, 1)
      const visitorData = this.keymetricList.find(val => val.id === 4)
      visitorData.percent = visitorReport.percent
      visitorData.value = visitorReport.total_new
      this.visitorChart = {
        chart: {
          xAxisData: visitorReport.chart.day,
          series: visitorReport.chart.new,
          series1: visitorReport.chart.old,
        },
        isLoaded: true,
      }
    },
    async loadConversion(dateFromTo) {
      const convenrsionReport = await this.loadChart(
        'conversion',
        dateFromTo,
        1,
      )
      const convenrsionData = this.keymetricList.find(val => val.id === 5)
      convenrsionData.percent = convenrsionReport.percent
      convenrsionData.value = convenrsionReport.total_new
    },
    // async loadSalePerOrder(dateFromTo) {
    //   const salePerOrderReport = await this.loadChart(
    //     'sales_per_orders',
    //     dateFromTo,
    //     1,
    //   )
    //   const salePerOrderData = this.keymetricList.find(val => val.id === 1)
    //   salePerOrderData.percent = salePerOrderReport.percent
    //   salePerOrderData.value = salePerOrderReport.total_new
    //   this.salePerOrderChart = {
    //     isLoaded: true,
    //     chart: {
    //       xAxisData: salePerOrderReport.chart.day,
    //       series: salePerOrderReport.chart.new,
    //     },
    //   }
    // },

    async loadChart(url, dateFromTo, type = 1) {
      try {
        let params = `?site_id=${
          JSON.parse(localStorage.getItem('siteID')).id
        }&type=${type}` // type = 1 sale | type = 2 order
        if (dateFromTo !== null) {
          const date = dateFromTo.split(' to ')
          params += `&date_from=${date[0]}`
          params += `&date_to=${date[1]}`
        }
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/statistic/${url}${params}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
  },
}
</script>
<style lang="sass" scoped>
.m-w-215
  min-width: 215px
.custom-setup,
.custom-todo
  &__items
    margin-bottom: 12px
    &:not(:last-child)
      border-bottom: 1px solid #e0e0e0
      padding-bottom: 12px
      margin-bottom: 12px
    a
      color: #1a1a1a
      &:hover
        color: var(--warning)
        span
          color: var(--warning)
      span
        color: var(--primary)
.custom-todo
  &__items
    font-size: 21px

.keys
  width: calc( 100%/5 - 16px )
  text-align: center
  border: 1px solid #e5e5e5
  border-radius: 5px
  margin: 0 16px
  padding: 16px
  color: #333
  &>*
    margin-bottom: 16px

  .text
    font-size: 16px
  .price
    font-size: 24px
  .sub
    color: #999
.text-active
  background-color: #e6e6e6
  padding: 1rem
  margin-right: 2px
  cursor: pointer
  &:hover
    border-top: 2px solid #2979FFCC
    background: transparent
.active
  // border-top: 2px solid #2979FFCC
  background: transparent
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

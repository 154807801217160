<template>
  <b-card no-body>
    <!-- <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('Indicators') }}
        </b-card-title>
        <b-card-sub-title>Commercial networks</b-card-sub-title>
      </div>
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
    </b-card-header> -->

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import flatPickr from 'vue-flatpickr-component'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  area: {
    series3: '#5787b7',
    series1: '#55aab3',
  },
}
export default {
  components: {
    BCard,
    VueApexCharts,
    // BCardHeader,
    BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    // flatPickr,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
      lineAreaChartSpline: {
        series: [
          {
            name: 'Now',
            data: this.optionData.series,
          },
          {
            name: 'Last',
            data: this.optionData.series1,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: this.optionData.xAxisData,
          },
          yaxis: {
            // opposite: isRtl,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          fill: {
            opacity: 0,
            // type: 'solid',
          },
          tooltip: {
            shared: true,
          },
          colors: [chartColors.area.series3, chartColors.area.series1],
        },
      },
    }
  },
}
</script>
